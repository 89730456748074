import React from 'react'
import { useIntl } from 'react-intl'

import { SEO, Albums } from '../components'

const metadata = [
  {
    folder: 'gallery/JedrzejowI',
    title: 'Jędrzejów I',
    cover: '1 po.jpg',
  },
  {
    folder: 'gallery/JedrzejowII',
    title: 'Jędrzejów II',
    cover: 'P1080568.jpg',
  },
  {
    folder: 'gallery/KielceI',
    title: 'Kielce I',
    cover: 'P1070049.jpg',
  },
  {
    folder: 'gallery/KielceII',
    title: 'Kielce II',
    cover: '1 po.jpg',
  },
  {
    folder: 'gallery/Nadarzyn',
    title: 'Nadarzyn',
    cover: 'c.jpg',
  },
  {
    folder: 'gallery/Piaseczna',
    title: 'Piaseczna',
    cover: 'IMG_0009.jpg',
  },
  {
    folder: 'gallery/Sandomierz',
    title: 'Sandomierz',
    cover: 'f.jpg',
  },
  {
    folder: 'gallery/Wola',
    title: 'Wola',
    cover: '100_0014.jpg',
  },
]

const Page = () => {
  const { formatMessage: f } = useIntl()

  return (
    <>
      <SEO title={f({ id: 'navigation.projekty' })} />

      <section className='py-8 px-4'>
        <div className='container mx-auto'>
          <h1 class='text-lg leading-6 font-medium text-gray-900 mb-4'>Wybrane Projekty</h1>
          <Albums metadata={metadata} />
        </div>
      </section>
    </>
  )
}

export default Page
